import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';
import Work from '../components/work';

import imgHero from '../assets/img/services/services.jpg';

import imgWorkToyota from '../assets/img/portfolio/toyota.jpg';
import imgWorkTeag from '../assets/img/portfolio/teag2.jpg';
import imgWorkEBquad from '../assets/img/portfolio/ebquad2.jpg';
import imgWorkRight from '../assets/img/portfolio/rightink.jpg';
import imgWorkIdmagic from '../assets/img/portfolio/idmagic.jpg';
import imgWorkFanny from '../assets/img/portfolio/fannywautelet.jpg';
import imgWorkLexus from '../assets/img/portfolio/lexus.jpg';
import SEO from '../components/seo';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Réalisations | Agence Digitale | MDS Digital Agency"
      description="Découvrez un aperçus des derniers projets réalisés par nos soins."
      keywords="réalisations"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
      <h2>Nos dernières réalisations</h2>
      <Work
        img={imgWorkToyota}
        title="Toyota Belgium"
        url="/realisations/toyota/"
        text="Chaque jour une information actualisée et un fonctionnement sans faille"
      ></Work>
      <Work
        img={imgWorkTeag}
        title="Touch English And Go"
        url="/realisations/touch-english-and-go/"
        text="Développement de formations et tests en ligne"
      ></Work>
      <Work
        img={imgWorkRight}
        title="Right Ink"
        url="/realisations/right-ink/"
        text="Retouche graphique et développement de nouvelles fonctionnalités"
      ></Work>
      {/* <Work 
      img={imgWorkEBquad}
      title="EB Quad"
      url="/realisations/eb-quad/"
      text="Amélioration continue et actualisation constante des informations"
    >
    </Work>
    <Work 
      img={imgWorkIdmagic}
      title="ID Magic"
      url="/realisations/idmagic/"
      text="Création du site internet étape par étape"
    >
    </Work> */}
      <Work
        img={imgWorkFanny}
        title="Fanny Wautelet"
        url="/realisations/fanny-wautelet/"
        text="Elaboration d’une stratégie digitale complète"
      ></Work>
      <Work
        img={imgWorkLexus}
        title="Lexus"
        url="/realisations/lexus/"
        text="Assistance quotidienne infaillible"
      ></Work>
    </article>
    <Footer />
  </div>
);
