import React from 'react';
import workStyle from '../components/work.module.css';
import 'aos/dist/aos.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class Work extends React.Component {
  componentDidMount() {
    const AOS = require('aos');
    this.aos = AOS;
    this.aos.init();
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    return (
      <AniLink
        cover
        bg="#fba515"
        className={workStyle.container}
        to={this.props.url}
        data-aos="fade-up"
      >
        <div className={workStyle.img} style={{ backgroundImage: 'url(' + this.props.img + ')' }} />
        <div className={workStyle.text}>
          <h3>{this.props.title}</h3>
          <p>{this.props.text}</p>
        </div>
      </AniLink>
    );
  }
}

export default Work;
